var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CRow",
    [
      _c(
        "CCol",
        { attrs: { md: "8" } },
        [
          _c(
            "CCard",
            { staticClass: "h-100 job-summary" },
            [
              _c(
                "CCardHeader",
                { staticClass: "pt-1 pb-0 mb-0", attrs: { id: "card-header" } },
                [
                  _c(
                    "p",
                    { staticClass: "card-title", attrs: { id: "job-title" } },
                    [_vm._v("Job Summary")]
                  ),
                  _c(
                    "p",
                    { staticClass: "text-right", attrs: { id: "truck-id" } },
                    [_c("strong", [_vm._v(" TRUCK #:" + _vm._s(_vm.truckID))])]
                  )
                ]
              ),
              _c(
                "CCardBody",
                [
                  _c(
                    "CRow",
                    [
                      _c("CCol", [
                        _c(
                          "p",
                          {
                            staticClass:
                              "card-text card-text-light data-heading"
                          },
                          [_vm._v("Site Location")]
                        ),
                        _c(
                          "p",
                          {
                            staticClass: "cart-text-dark text-display",
                            attrs: { id: "job-address" }
                          },
                          [
                            _vm.siteAddress.street
                              ? [
                                  _vm._v(
                                    " " + _vm._s(_vm.siteAddress.street) + " "
                                  ),
                                  _c("br")
                                ]
                              : _vm._e(),
                            _vm._v(
                              " " +
                                _vm._s(_vm.siteAddress.city) +
                                ", " +
                                _vm._s(
                                  _vm.siteAddress.province ||
                                    _vm.siteAddress.state
                                ) +
                                " "
                            )
                          ],
                          2
                        ),
                        _c(
                          "p",
                          { staticClass: "card-text-light data-heading" },
                          [_vm._v(" Start")]
                        ),
                        _vm.jobSummaryData
                          ? _c(
                              "p",
                              {
                                staticClass: "text-display",
                                attrs: { id: "job-start" }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.converToDateTimeString(
                                        _vm.jobSummaryData.start
                                      )
                                    ) +
                                    " "
                                )
                              ]
                            )
                          : _vm._e(),
                        _c(
                          "p",
                          { staticClass: "card-text-light data-heading" },
                          [_vm._v(" End")]
                        ),
                        _vm.jobSummaryData
                          ? _c(
                              "p",
                              {
                                staticClass: "text-display",
                                attrs: { id: "job-end" }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.converToDateTimeString(
                                        _vm.jobSummaryData.end
                                      )
                                    ) +
                                    " "
                                )
                              ]
                            )
                          : _vm._e()
                      ]),
                      _c("CCol", [
                        _vm.operators.length > 0
                          ? _c(
                              "p",
                              {
                                staticClass: "card-text-light data-heading",
                                attrs: { id: "operators-label" }
                              },
                              [_vm._v("Operators")]
                            )
                          : _vm._e(),
                        _c(
                          "ul",
                          {
                            staticClass: "list-group bg-none",
                            attrs: { id: "operators-list" }
                          },
                          _vm._l(_vm.operators, function(item) {
                            return _c(
                              "li",
                              {
                                key: item.name,
                                staticClass:
                                  "list-group-item bg-none borderless m-0 pt-0"
                              },
                              [_vm._v(" " + _vm._s(item.name) + " ")]
                            )
                          }),
                          0
                        ),
                        _c(
                          "p",
                          { staticClass: "card-text-light data-heading" },
                          [_vm._v("Total Job Time")]
                        ),
                        _c(
                          "p",
                          {
                            staticClass: "time-display",
                            attrs: { id: "job-total-time" }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.convertMsToString(
                                    _vm.jobSummaryData.totalJobTime
                                  )
                                ) +
                                " "
                            )
                          ]
                        ),
                        _c(
                          "p",
                          { staticClass: "card-text-light data-heading" },
                          [_vm._v(" Total Water On Time")]
                        ),
                        _c(
                          "p",
                          {
                            staticClass: "time-display",
                            attrs: { id: "job-water-on-time" }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.convertMsToString(
                                    _vm.jobSummaryData.totalWaterTime
                                  )
                                ) +
                                " "
                            )
                          ]
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "CCol",
        [
          _c(
            "CCard",
            { staticClass: "h-100", staticStyle: { "min-height": "200px" } },
            [
              _c(
                "CCardBody",
                { staticClass: "m-0 p-1" },
                [
                  _c("SimpleLeafletMap", {
                    staticClass: "h-100",
                    attrs: { marker: _vm.center, center: _vm.center }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }