

<template>
  <CRow>
    <CCol md="8">
      <CCard  class="h-100 job-summary">
        <CCardHeader class="pt-1 pb-0 mb-0" id="card-header">
          <p id="job-title" class="card-title">Job Summary</p>
          <p id="truck-id" class="text-right"> <strong> TRUCK #:{{ truckID }}</strong> </p>
        </CCardHeader>
        <CCardBody>
          <CRow> 
              <CCol>
                <p class="card-text card-text-light data-heading">Site Location</p>
                <p id="job-address" class="cart-text-dark text-display">
                <template v-if="siteAddress.street">
                {{siteAddress.street}} <br>
                </template>
                {{siteAddress.city}}, {{siteAddress.province || siteAddress.state}}
                </p>
                <p class="card-text-light data-heading"> Start</p>
                <p id="job-start" class="text-display" v-if="jobSummaryData">
                  {{converToDateTimeString(jobSummaryData.start)}}
                </p>
                 <p class="card-text-light data-heading"> End</p>
                <p id="job-end" class="text-display" v-if="jobSummaryData">
                    {{converToDateTimeString(jobSummaryData.end)}}
                </p>

              </CCol>
              <CCol>
                  <p id="operators-label" class="card-text-light data-heading" v-if="operators.length>0">Operators</p>
                  <ul id="operators-list" class="list-group bg-none">
                    <li v-for="item in operators" :key="item.name" class="list-group-item bg-none borderless m-0 pt-0">
                        {{item.name}}
                      </li>
                  </ul>
                <p class="card-text-light data-heading">Total Job Time</p>
                <p id="job-total-time" class="time-display">
                      {{convertMsToString(jobSummaryData.totalJobTime)}}
                </p>
                 <p class="card-text-light data-heading"> Total Water On Time</p>
                <p id="job-water-on-time" class="time-display">
                    {{convertMsToString(jobSummaryData.totalWaterTime)}}
                </p>
              </CCol>

          </CRow>
        </CCardBody>
      </CCard>
    </CCol>

    <CCol >
      <CCard class="h-100" style="min-height:200px">
        <CCardBody class="m-0 p-1"> 
            
            <SimpleLeafletMap class="h-100"
              :marker="center"
              :center="center"
              >
            </SimpleLeafletMap>

        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>




<script>
//import MapWrapper from "../dashboard/MapWrapper";
import SimpleLeafletMap from "../dashboard/SimpleLeafletMap"
import CReportCard from "./CReportCard"; 
export default {
  name: "JobSummary",
  extends: CReportCard,
  components: {
    //MapWrapper,
    SimpleLeafletMap
  },
  props: {
      title: String, 
      truckID: {type: String, default:"Truck" },
      jobSummaryData: {type: Object, 
      default: {
          start: 0, 
          end: 0, 
          totalJobTime: 0, 
          totalWaterTime: 0, 
          
      },
      },
      location: Object,
      siteAddress: {type: Object, default: {
            street: "", 
            city: "",
            province: "",
            state: "",
        }
        }
  },
  data() {
    return {
      //truckID: "717422", 
      operators:[],
      //location: [0.0,0.0],
       
      
    };
  },
  computed:{
      center(){
        if(!this.location) 
          return [0.0,0.0]; 
        
        return [this.location.lat, this.location.lng]; 
      },
     
  },
  methods:{

    converToDateTimeString(data){
      // TODO: Get User's prefered date time format 
      
        return this.$moment(data).format("MMM DD, YYYY hh:mm:ss A"); 
    },
          /*
          Parses the standard lat & lng format into a human readable string
          by appending N, W, S, W instead of using the +/- Symbols
          Also provides fixed precision
          TODO: Design a Vue Component that manages this
          */
          convertCoordinatesToString :function(data){
              if(!data)
                return null;
              else {
                var coordString = "";
                var coordVal = parseFloat(data.lat);
                coordString += coordVal.toFixed(4);
                if(coordVal>0){
                  coordString += " N";
                }
                else {
                  coordString += " S";
                }
                coordString += ", "
                coordVal = parseFloat(data.lng);
                coordString += coordVal.toFixed(4);
                if(coordVal>0){
                  coordString += " E";
                }
                else {
                  coordString += " W";
                }
              }
              return coordString;

          },
          convertMsToString: function(elapsed) {
            var temp = this.$moment.utc(elapsed);
            var hours = elapsed / (1000 * 3600);

            // TODO; Format may vary depending on region;
            let timeUnits={hour: 'hr',min: 'm', sec: "s"};

            // TODO: Load local time units from regional settings for organization

            var myTimeString = "";
            if (hours > 1.0)
              myTimeString += temp.format("H") + timeUnits.hour + " "; // Must be 24 Hour Format  myTimeString +=

            myTimeString += temp.format("m") + timeUnits.min + " ";
            myTimeString += temp.format("ss") + timeUnits.sec + " ";

            return myTimeString // Only Return the Hours and Seconds.
          },
        /*Helper functiont that converts a boolean value to regional language String*/
        convertBooleanToHumanString: function(value){
            if(typeof(value) === "boolean"){
                if(value){
                  return "Yes"; // TODO: return regional language
                }
                else {
                  return "No";
                }
            }
        },
  }
};
</script>

<style scoped>
#truck-id {
  text-align: right;
  position: relative;
  float: right;
}
#job-title {
  text-align: left;
  position: relative;
  float: left;
}





</style>