

<script>
export default {
    name: "ReportCard", 
    methods:{

    converToDateTimeString(data){
      // TODO: Get User's prefered date time format 
        return this.$moment(data).format("MMM DD, YYYY hh:mm:ss A"); 
    },
          /*
          Parses the standard lat & lng format into a human readable string
          by appending N, W, S, W instead of using the +/- Symbols
          Also provides fixed precision
          TODO: Design a Vue Component that manages this
          */
          convertCoordinatesToString :function(data){
              if(!data)
                return null;
              else {
                var coordString = "";
                var coordVal = parseFloat(data.lat);
                coordString += coordVal.toFixed(4);
                if(coordVal>0){
                  coordString += " N";
                }
                else {
                  coordString += " S";
                }
                coordString += ", "
                coordVal = parseFloat(data.lng);
                coordString += coordVal.toFixed(4);
                if(coordVal>0){
                  coordString += " E";
                }
                else {
                  coordString += " W";
                }
              }
              return coordString;

          },
          convertMsToString: function(elapsed) {
            var temp = this.$moment.utc(elapsed);
            var hours = elapsed / (1000 * 3600);

            // TODO; Format may vary depending on region;
            let timeUnits={hour: 'hr',min: 'm', sec: "s"};

            // TODO: Load local time units from regional settings for organization

            var myTimeString = "";
            if (hours > 1.0)
              myTimeString += temp.format("H") + timeUnits.hour + " "; // Must be 24 Hour Format  myTimeString +=

            myTimeString += temp.format("m") + timeUnits.min + " ";
            myTimeString += temp.format("ss") + timeUnits.sec + " ";

            return myTimeString // Only Return the Hours and Seconds.
          },
        /*Helper functiont that converts a boolean value to regional language String*/
        convertBooleanToHumanString: function(value){
            if(typeof(value) === "boolean"){
                if(value){
                  return "Yes"; // TODO: return regional language
                }
                else {
                  return "No";
                }
            }
        },
    }
}
</script>